import React, { FC, Children } from 'react'
import Link, { LinkProps } from 'next/link'
import c from 'clsx'
import { useRouter } from 'next/router'

interface NavLinkProps {
  activeClassName?: string
  children?: React.ReactNode
}

const NavLink: FC<NavLinkProps & LinkProps> = ({ children, activeClassName, ...props }) => {
  const { asPath } = useRouter()
  const child = Children.only(children)
  //@ts-ignore
  let className = child.props.className || ''
  const active = asPath === props.href || asPath === props.as

  return (
    <Link {...props}>
      {/*@ts-ignore*/}
      {children && React.cloneElement(child, { className: c(className, active && activeClassName) })}
    </Link>
  )
}

export default NavLink